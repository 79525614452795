<template>
  <div style="min-height: 70vh">
    <loader> </loader>
  </div>
</template>
<script>
export default {};
</script>

<style>
.loader {
  top: 0;
  left: 0;
  right: 0;
  border: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.7);
  color: whitesmoke;
  flex-direction: column;
}
</style>
